<script>
import Base from '@backend/Base.vue';
import Gen from '@helper/Gen';
export default {
	name: "BoProfile",
	extends: Base,
	data(){
		return {
			Name:"BoProfile",
			mrLevel: {},
		}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData(()=>{
			setTimeout(()=>{this.$set(this.row, 'type', 'update')},500)
		})
	},
	methods:{
		successFormCallback(resp){
			Gen.info(resp.message, "success", 3000)
			this.refreshUser()
		}
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		}
	}
};
</script>
<template>
<div class="container-fluid">
<PageTitle></PageTitle>
<!-- ========================================================================
	FORM CRUD
============================================================================= -->
<div class="card">
	<VForm @resp="submitForm" method="post">
	<div class="card-body">
		<div class="info"></div>
		<div class="row">
			<div class="col-sm-9">
				<BoField v-bind="validation('bu_full_name')" name="bu_full_name" :label="'Nama Lengkap'" v-model="row.bu_full_name"></BoField>
				<BoField v-bind="validation('bu_username')" name="bu_username" v-model="row.bu_username" keyFilter="usernameKey">
				<span slot="help" class="help-block">
					<small class="d-block">{{ fields.bu_username }} untuk login ke halaman backoffice</small>
				</span>
				</BoField>
				<BoField v-bind="validation('bu_password')" name="bu_password" v-model="row.bu_password" :attr="{type:'password'}"></BoField>
				<BoField v-bind="validation('bu_email_address')" name="bu_email_address" v-model="row.bu_email_address" :attr="{type:'email'}"></BoField>
				
        <BoField 
					v-bind="validation('bu_phone')"
          name="bu_phone" 
          :label="'No Telepon'" 
          :attr="{type: 'tel', maxlength: '13',  placeholder: 'e.g. 08821989287'}" 
          v-model="row.bu_phone" keyFilter="mobileKey"
        ></BoField>
			</div>
			<div class="col-sm-3">
				<BoField name="bu_pic" :label="'Foto Profil'">
				<Uploader name="bu_pic" type="users" uploadType="cropping" :deleted="false" :param="{thumbnail:true}" v-model="row.bu_pic"></Uploader>
				</BoField>
			</div>
			<div class="col-sm-12">
				<div class="text-right">
					<button type="submit" class="fcbtn btn btn-rounded btn-info btn-outline btn-1e btn-loading">Perbarui <i class="icon-arrow-right14 position-right"></i></button>
				</div>
			</div>
		</div>
	</div>
	</VForm>
</div>
</div>
</template>